h1,
.h1 {
  color: var(--deco-color);
  font: 500 clamp(34px, 5vw, 44px)/clamp(44px, 6vw, 54px) var(--primary-font);
  padding: 0 0 18px 0;
}
h2,
.h2 {
  color: var(--deco-color);
  font: 500 clamp(30px, 5vw, 36px)/clamp(38px, 6vw, 44px) var(--primary-font);
  padding: 0 0 20px 0;
}
h3,
.h3 {
  font: clamp(30px, 5vw, 36px)/clamp(38px, 6vw, 44px) var(--primary-font);
  padding: 0 0 20px 0;
}
h4,
.h4 {
  font: 28px/34px var(--primary-font);
  padding: 0 0 20px 0;
}
h5,
.h5 {
  font: 18px/27px var(--primary-font);
  padding: 0 0 20px 0;
}
h6,
.h6 {
  font: 16px/25px var(--primary-font);
  padding: 0 0 20px 0;
}
ul,
ol {
  &:not([class]) {
    font: 16px/24px var(--primary-font);
    padding: 0 0 20px 0;
    li {
      &:before {
        display: inline-block;
      }
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 5px;
        height: 5px;
        content: "";
        background: var(--text-color);
        border-radius: 50%;
        margin: 0 8px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    li {
      &:before {
        font-weight: bold;
        content: counter(li) ".";
        counter-increment: li;
        margin: 0 5px 0 0;
      }
    }
  }
}
p {
  font: 16px/24px var(--primary-font);
  padding: 0 0 20px 0;
  &.small {
    font: 12px/18px var(--primary-font);
  }
}
a {
  &:not([class]) {
    color: var(--text-color);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &[href^="mailto:"],
  &[href^="http"] {
    word-break: break-all;
  }
  &:focus-visible {
    outline: 1px solid var(--deco-color);
  }
}
.text-color {
  color: var(--text-color) !important;
}
.text-color-deco {
  color: var(--deco-color) !important;
}
.text-color-error {
  color: var(--error-color) !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-normal {
  font-weight: normal !important;
}