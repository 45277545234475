// imports top
@import "config";
@import "normalize";
@import "reset";
@import "typography";
@import "form";
@import "swiper-bundle.min";
@import "plugins";
body,
html {
  height: 100%;
}
body {
  color: var(--text-color);
  font: 16px/24px var(--primary-font);
}
.wrapper {
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1190px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  margin: 0 auto;
  &--tight {
    max-width: 1070px;
  }
  &--flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}
.header {
  min-height: 550px;
  background: url(../img/header-bg.jpg) no-repeat var(--additional-color) calc(50% - 440px) top;
  &__top {
    padding: 35px 0;
    position: relative;
  }
  &__body {
    padding: 35px 0 0 0;
  }
  &__buttons {
    align-items: center;
    display: flex;
    gap: 16px;
  }
  &__text {
    align-self: flex-start;
    flex: 1 1 0;
    .home & {
      padding: 15px 80px 30px 0;
    }
    .inner & {
      max-width: 762px;
      text-align: center;
      padding: 35px 0 30px 0;
      margin: 0 auto;
    }
  }
  &__image {
    max-width: 40%;
    margin: 0 0 0 auto;
    align-self: flex-end;
  }
}
.site {
  &-logo {
    display: inline-block;
    &--bottom {
      margin: 0 0 20px 0;
    }
  }
  &-hamburger {
    height: 45px;
    margin: 0 0 0 10px;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity;
    position: relative;
    align-items: center;
    display: none;
    &__box {
      width: 29px;
      height: 19px;
      position: relative;
      display: block;
    }
    &__inner {
      top: 50%;
      margin-top: -2px;
      display: block;
    }
    &__inner,
    &__inner:before,
    &__inner:after {
      width: 29px;
      height: 2px;
      background: var(--secondary-deco-color);
      position: absolute;
      transition: transform .15s ease;
    }
    &__inner:before,
    &__inner:after {
      content: "";
      display: block;
    }
    &__inner:before {
      top: -8px;
    }
    &__inner:after {
      top: -16px;
    }
    &--collapse &__inner {
      top: auto;
      bottom: 0;
      transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background var(--primary-transition);
      &:after {
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background var(--primary-transition);
      }
      &:before {
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background var(--primary-transition);
      }
    }
    &--collapse.active &__inner,
    a.active &--collapse &__inner {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      &:after {
        top: 0;
        opacity: 0;
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
      }
      &:before {
        top: 0;
        transform: rotate(-90deg);
        transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
      }
    }
  }
}
.nav {
  &-main {
    margin: 0 auto;
    &__list {
      align-items: center;
      display: flex;
      gap: 10px 22px;
    }
    &__links {
      color: var(--text-color);
      font: 16px/24px var(--secondary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      &:hover {
        color: var(--deco-color);
      }
    }
  }
  &-side {
    padding: 18px 0;
    &__list {
      display: flex;
      flex-flow: column nowrap;
      gap: 12px;
    }
    &__links {
      color: var(--secondary-deco-color);
      font: 24px/36px var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: block;
      &:hover {
        color: var(--deco-color);
      }
      &.active {
        color: var(--deco-color);
        font-weight: bold;
      }
    }
  }
  &-bottom {
    &__list {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
    }
    &__links {
      color: var(--text-color);
      font: 16px/24px var(--secondary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: inline-flex;
      gap: 12px;
      &:hover {
        color: var(--deco-color);
      }
    }
  }
}
.body {
  padding-bottom: 140px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  .home & {
    background: var(--elements-color);
    box-shadow: 0 -4px 50px -2px rgba(0, 0, 0, .25);
    border-radius: 60px 60px 0 0;
    padding-top: 75px;
    margin: -50px 0 0 0;
  }
  .inner & {
    padding-top: 75px;
  }
}
.badge {
  &-list {
    max-width: 810px;
    margin: 0 auto 160px auto;
    grid-template-columns: repeat(auto-fit, minmax(132px, 1fr));
    display: grid;
    gap: 30px 94px;
    &__items {
      color: var(--secondary-text-color);
      font: 18px/27px var(--secondary-font);
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      flex-flow: column nowrap;
      gap: 10px;
      img {
        max-width: 100%;
      }
    }
  }
}
.about {
  &-product {
    min-height: 388px;
    background: url(../img/about-product-bg.png) no-repeat center center / contain;
    padding: 40px 0 0 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    &__col {
      width: 240px;
      max-width: 28%;
      p {
        font: 12px/18px var(--primary-font);
        padding: 0 0 10px 0;
      }
    }
    &__number {
      color: var(--deco-color);
      font: bold 50px/1 var(--primary-font);
      opacity: .6;
      display: block;
    }
    &__button {
      width: 100%;
      text-align: center;
      margin: -15px 0 160px 0;
    }
  }
}
.slider {
  &-plans {
    padding: 0 10px;
    margin: 0 0 20px 0;
    position: relative;
    .swiper {
      padding: 0 15px;
    }
    .swiper-wrapper {
      padding: 20px 0;
    }
    &__slide {
      box-shadow: 2px 2px 30px -12px rgba(0, 0, 0, .2);
      border-radius: 20px;
      padding: 30px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      &--pattens-blue {
        background: var(--pattens-blue);
      }
      &--zumthor {
        background: var(--zumthor);
      }
      &--blue-chalk {
        background: var(--blue-chalk);
      }
    }
    &__text {
      font: 18px/27px var(--secondary-font);
      align-items: flex-start;
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 0;
      gap: 20px;
    }
    &__label {
      color: var(--secondary-text-color);
      font: 20px/30px var(--secondary-font);
      text-transform: uppercase;
      border: 1px solid var(--secondary-deco-color);
      border-radius: var(--primary-radius);
      padding: 12px 20px 11px 20px;
      display: inline-block;
    }
    &__price {
      color: var(--secondary-text-color);
      font: bold 60px/1 var(--secondary-font);
      display: inline-block;
    }
    &__features {
      font: 16px/20px var(--secondary-font);
      align-items: flex-start;
      display: flex;
      flex-flow: column nowrap;
      gap: 12px;
      &__items {
        background: url(../img/icon-features.svg) no-repeat;
        padding: 0 0 0 30px;
        &--desabled {
          opacity: .3;
          background: url(../img/icon-features-disabled.svg) no-repeat;
        }
      }
    }
    &__prev,
    &__next {
      width: 26px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 15px);
      z-index: 1;
    }
    &__prev {
      background: url(../img/slider-prev.svg) no-repeat;
      left: -10px;
      &.swiper-button-disabled {
        background: url(../img/slider-prev-disabled.svg) no-repeat;
      }
    }
    &__next {
      background: url(../img/slider-next.svg) no-repeat;
      right: -10px;
      &.swiper-button-disabled {
        background: url(../img/slider-next-disabled.svg) no-repeat;
      }
    }
    &__more {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }
}
.aside {
  padding: 155px 25px 50px 0;
  margin: -155px 0 0 0;
  isolation: isolate;
  position: relative;
  align-self: stretch;
  flex: 0 0 284px;
  &:before {
    width: 50vw;
    content: "";
    background: var(--elements-color);
    box-shadow: 2px 2px 30px -11px rgba(0, 0, 0, .2);
    border-radius: 0 20px 20px 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
.content {
  padding: 0 0 0 42px;
  align-self: flex-start;
  flex: 1 1 0;
}
.news {
  &-block {
    background: var(--zircon);
    border-radius: var(--primary-radius);
    padding: 30px;
    margin: 40px 0 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 50px;
    &--reverse {
      flex-direction: row-reverse;
    }
    &__image {
      max-width: 50%;
      border-radius: var(--primary-radius);
      align-self: flex-start;
    }
    &__text {
      & > *:last-child {
        padding-bottom: 0;
      }
    }
  }
}
.footer {
  background: var(--additional-color);
  padding: 40px 0;
  &__wrap {
    grid-template-columns: 4fr 1fr 1fr 2fr;
    display: grid;
    gap: 80px;
  }
  &__col {
    &:first-child {
      padding-right: 150px;
    }
  }
}
// imports bottom
@import "tablet";
@import "mobile";
@import "helpers";