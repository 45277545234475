// media queries scss
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1189px;
$desktop-min: 1190px;

// helpers loop variables scss
$padding-top-bottom-from: 0;
$padding-top-bottom-to: 0;
$padding-top-bottom-step: 2;

$padding-left-right-from: 0;
$padding-left-right-to: 0;
$padding-left-right-step: 2;

$margin-top-bottom-from: 0;
$margin-top-bottom-to: 0;
$margin-top-bottom-step: 2;

$margin-left-right-from: 0;
$margin-left-right-to: 0;
$margin-left-right-step: 2;

// helpers loop padding and margin top left right bottom scss
$helpers-xl: false; // desktop
$helpers-sm: false; // tablet
$helpers-xs: false; // mobile

:root {
  /* colors with hex values */
  --black: #000;
  --outer-space: #333639;
  --white: #fff;
  --silver-chalice: #a5a5a5;
  --royal-blue: #527DE0;
  --zumthor: #E6EDFF;
  --zircon: #F5F8FF;
  --pattens-blue: #DFF5FF;
  --blue-chalk: #F6E6FF;
  --monza: #d60017;

  /* reassign color vars to semantic color scheme */
  --text-color: var(--black);
  --secondary-text-color: var(--outer-space);
  --elements-color: var(--white);
  --deco-color: var(--royal-blue);
  --secondary-deco-color: var(--outer-space);
  --additional-color: var(--zumthor);

  /* font */
  --primary-font: "Montserrat", sans-serif;
  --secondary-font: "Poppins", sans-serif;

  /* other elements */
  --primary-transition: .2s linear;
  --placeholder-color: var(--outer-space);
  --focus-color: var(--black);
  --disabled-color: var(--outer-space);
  --border-color: var(--royal-blue);
  --primary-radius: 10px;
  --error-color: var(--monza);
  --gutter: 15px;

  /* media queries css */
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
}