@media (max-width: $mobile-max) {
  body {
    min-width: 360px;
  }
  .header {
    min-height: 0;
    .home & {
      padding: 0 0 40px 0;
    }
    .inner & {
      padding: 0 0 20px 0;
      position: relative;
      z-index: 1;
    }
    &__top {
      padding: 20px 0;
    }
    &__body {
      padding: 15px 0 0 0;
    }
    &__buttons {
      margin: 0 0 0 auto;
      gap: 10px;
    }
    &__text {
      flex: 0 0 100%;
      .home & {
        padding: 0;
      }
      .inner & {
        padding: 0;
      }
    }
  }
  .site {
    &-hamburger {
      display: inline-flex;
    }
  }
  .nav {
    &-main {
      text-align: center;
      opacity: 0;
      background: var(--elements-color);
      transition: all var(--primary-transition);
      transform: translateY(-15px);
      visibility: hidden;
      box-shadow: 2px 2px 30px -12px rgba(0, 0, 0, .2);
      border-top: 1px solid var(--secondary-deco-color);
      border-bottom: 1px solid var(--secondary-deco-color);
      padding: 15px 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      &__list {
        flex-flow: column nowrap;
      }
      &__items {
        width: 100%;
      }
      &__links {
        padding: 0 var(--gutter);
        display: block;
      }
    }
    &-side {
      transition: all var(--primary-transition);
      padding: 0;
      grid-template-rows: 0fr;
      display: grid;
      &.open {
        grid-template-rows: 1fr;
      }
      &__list {
        overflow: hidden;
        gap: 5px;
      }
      &__items {
        &:first-child {
          padding-top: 20px;
        }
      }
      &__links {
        font: 20px/30px var(--primary-font);
      }
    }
  }
  .body {
    padding-bottom: 40px;
    .home & {
      border-radius: 30px 30px 0 0;
      padding-top: 30px;
      margin: -50px 0 0 0;
    }
  }
  .badge {
    &-list {
      margin-bottom: 60px;
      gap: 30px 20px;
    }
  }
  .about {
    &-product {
      min-height: 388px;
      background: url(../img/about-product-bg.png) no-repeat center calc(50% + 20px) / auto 200px;
      padding: 0;
      gap: 220px;
      &__col {
        width: 100%;
        max-width: 100%;
      }
      &__button {
        margin: 15px 0 60px 0;
      }
    }
  }
  .aside {
    text-align: center;
    padding: 20px 15px;
    margin: -75px 0 40px 0;
    isolation: isolate;
    position: relative;
    align-self: stretch;
    flex: 0 0 100%;
    &:before {
      width: 100%;
      border-radius: 0 0 20px 20px;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &__toggle {
      color: var(--text-color);
      font: 24px/30px var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 10px;
      &:hover,
      &.active {
        color: var(--deco-color);
        &:after {
          border-top-color: var(--deco-color);
        }
      }
      &.active {
        &:after {
          transform: rotate(180deg);
        }
      }
      &:after {
        width: 0;
        height: 0;
        content: "";
        transition: all var(--primary-transition);
        border-top: 5px solid var(--secondary-deco-color);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 0;
      }
    }
  }
  .content {
    padding: 0;
    flex: 0 0 100%;
  }
  .news {
    &-block {
      padding: 20px;
      flex-wrap: wrap;
      gap: 20px;
      &__image {
        max-width: 100%;
      }
      &__text {
        flex: 0 0 100%;
      }
    }
  }
  .footer {
    padding: 20px 0;
    &__wrap {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 20px;
    }
    &__col {
      &:first-child,
      &:last-child {
        padding-right: 0;
        grid-column: span 2;
      }
    }
  }
  .btn {
    &--default,
    &--transparent {
      padding: 6px 20px;
    }
  }
}