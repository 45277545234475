@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .badge {
    &-list {
      gap: 30px;
    }
  }
  .aside {
    flex: 0 0 210px;
  }
  .news {
    &-block {
      padding: 20px;
      gap: 20px;
      &__image {
        max-width: 50%;
        border-radius: var(--primary-radius);
      }
    }
  }
  .footer {
    padding: 20px 0;
    &__wrap {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px 20px;
    }
    &__col {
      &:first-child {
        padding-right: 0;
        grid-column: span 3;
      }
    }
  }
}