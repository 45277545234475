.form {
  &-regular {
    background: var(--zircon);
    border-radius: var(--primary-radius);
    padding: 30px;
    margin: 40px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    &__items {
      width: 100%;
    }
    &__label {
      color: var(--text-color);
      font: 16px/24px var(--primary-font);
      padding: 0 0 6px 0;
      display: block;
    }
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
select,
textarea {
  width: 100%;
  color: var(--text-color);
  font: 16px var(--primary-font);
  background: var(--elements-color);
  transition: all var(--primary-transition);
  outline: 1px solid var(--border-color);
  border: none;
  border-radius: 0;
  padding: 10px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat var(--elements-color) calc(100% - 5px) calc(50% - 1px);
  padding-right: 30px;
}
textarea {
  height: 120px;
  resize: none;
  display: block;
  &[rows] {
    height: auto;
  }
}
input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]))::placeholder,
textarea::placeholder {
  color: var(--placeholder-color) !important;
  opacity: 1;
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):hover,
select:hover,
textarea:hover {
  outline-color: var(--focus-color);
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):focus,
select:focus,
textarea:focus {
  outline-color: var(--focus-color);
  box-shadow: 0 2px 2px rgba(0, 0, 0, .16);
}
input.error:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
select.error,
textarea.error {
  outline-color: var(--error-color) !important;
}
input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    min-height: 20px;
    font: 14px/1.2 var(--primary-font);
    cursor: pointer;
    white-space: pre-wrap;
    padding: 2px 0 0 30px;
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      border: 1px solid var(--border-color);
      transition: all var(--primary-transition);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:before {
      width: 20px;
      height: 20px;
    }
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.error {
    & + label {
      &:before {
        border-color: var(--error-color);
      }
    }
  }
  &:disabled {
    & + label {
      &:before {
        background: var(--border-color);
      }
    }
  }
}
input[type=checkbox] {
  & + label {
    &:after {
      width: 18px;
      height: 18px;
      background: url(../img/check.svg) no-repeat;
      border: none;
      padding: 3px 0 0 1px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
input[type=radio] {
  & + label {
    &:before {
      border-radius: 50%;
    }
    &:after {
      width: 8px;
      height: 8px;
      background: var(--deco-color);
      border-radius: 50%;
      top: 6px;
      left: 6px;
    }
  }
}
.btn {
  text-align: center;
  text-decoration: none;
  transition: all var(--primary-transition);
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 12px;
  &--regular {
    color: var(--elements-color);
    font: 14px/21px var(--secondary-font);
    background: var(--deco-color);
    border: 1px solid var(--deco-color);
    border-radius: var(--primary-radius);
    padding: 6px 28px;
    &:hover {
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, .25));
    }
  }
  &--default {
    color: var(--elements-color);
    font: 300 14px/21px var(--secondary-font);
    background: var(--secondary-deco-color);
    border: 1px solid var(--secondary-deco-color);
    border-radius: var(--primary-radius);
    padding: 6px 28px;
    &:hover {
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, .25));
    }
  }
  &--transparent {
    color: var(--text-color);
    font: 300 14px/21px var(--secondary-font);
    background: none;
    border: 1px solid var(--secondary-deco-color);
    border-radius: var(--primary-radius);
    padding: 6px 26px;
    &:hover {
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, .25));
    }
  }
  &--med {
    font: 16px/24px var(--secondary-font);
    padding: 10px 28px;
    &:hover {
      transform: scale(1.05);
    }
  }
  &--block {
    width: 100%;
  }
  &:disabled {
    color: var(--disabled-color);
    cursor: not-allowed;
    background: var(--disabled-color);
  }
}